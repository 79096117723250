
// Load jQuery from NPM
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import lozad from 'lozad';
import ModalVideo from 'modal-video';
// import { saveAs } from './file-saver';
// import AssetCart from 'asset-cart';

// import './css/fromjs.sass'

gsap.registerPlugin(ScrollTrigger);
// console.log('hello');
// (function() {


//  })();
$(document).ready(function () {

    new ModalVideo('.js-modal-btn');

    var tl = gsap.timeline();
    tl.from(".hero span", { duration: 2, opacity: 0, y: 60, stagger: 0.35 });

    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();

    const phases = gsap.utils.toArray(".complete-journey .phase");
    const panels = gsap.utils.toArray(".complete-journey .panel");
    const cardWrap = gsap.utils.toArray(".complete-journey .card-wrap");
    const offCanvas = gsap.utils.toArray(".offcanvas-menu a");
    const navLinks = gsap.utils.toArray(".section-nav a");
    const completeJourney = gsap.utils.toArray("a.complete-journey-btn");
    const sections = gsap.utils.toArray(".section");
    const topNav = document.querySelector(".menu-bar");
    let navHeight = topNav.offsetHeight


    /* Shapes */
    const discoverContainer = document.getElementById('discoverAnimation');
    const discoverAnim = bodymovin.loadAnimation({
        wrapper: discoverContainer,
        animType: 'svg',
        loop: true,
        autoplay: false,
        path: './javascripts/Discover.json'
    });

    /* Shapes */
    const defineContainer = document.getElementById('defineAnimation');
    const defineAnim = bodymovin.loadAnimation({
        wrapper: defineContainer,
        animType: 'svg',
        loop: true,
        autoplay: false,
        path: './javascripts/Define.json'
    });

    /* Shapes */
    const deployContainer = document.getElementById('deployAnimation');
    const deployAnim = bodymovin.loadAnimation({
        wrapper: deployContainer,
        animType: 'svg',
        loop: true,
        autoplay: false,
        path: './javascripts/Deploy.json'
    });

    const deliverContainer = document.getElementById('deliverAnimation');
    const deliverAnim = bodymovin.loadAnimation({
        wrapper: deliverContainer,
        animType: 'svg',
        loop: true,
        autoplay: false,
        path: './javascripts/Deliver.json'
    });


    const evolveContainer = document.getElementById('evolveAnimation');
    const evolveAnim = bodymovin.loadAnimation({
        wrapper: evolveContainer,
        animType: 'svg',
        loop: true,
        autoplay: false,
        path: './javascripts/Evolve.json'
    });



    const mediaQueryForCarousel = window.matchMedia('(max-width: 1200px)');

    mediaQueryForCarousel.addEventListener('change', carouselMediaCall)

    function carouselMediaCall(){    

        if (mediaQueryForCarousel.matches) {
           
            // play animations
            // if ($('.discover.main').hasClass('mobile-reveal')) {
            //     discoverAnim.play();
            //     console.log('discover play')
            // } else {
            //     discoverAnim.pause();
            // }

            // if ($('.define-panel').hasClass('mobile-reveal')) {
            //     defineAnim.play();
            //     console.log('define play')
            // } else {
            //     defineAnim.pause();
            // }

            // if ($('.deploy-panel').hasClass('mobile-reveal')) {
            //     deployAnim.play();
            //     console.log('deploy play')
            // } else {
            //     deployAnim.pause();
            // }

            // if ($('.deliver-panel').hasClass('mobile-reveal')) {
            //     deliverAnim.play();
            //     console.log('deliver play')
            // } else {
            //     deliverAnim.pause();
            // }

            // if ($('.evolve-panel').hasClass('mobile-reveal')) {
            //     evolveAnim.play();
            //     console.log('evolve play')
            // } else {
            //     evolveAnim.pause();
            // }


        } else {
            // console.log('nnnnokiiii')
            // Complete Journey Trigger

            // $('.complete-journey button.main, .complete-journey h1.main').on('click', function () {

            //     var content = $(this).attr('data-type');

            //     console.log(content)

            //     // $('[data-type = ' + content + ']').toggleClass('reveal')

            //     let items = document.body.querySelectorAll('[data-type="' + content + '"]')
            //     // console.log( items)
            //     items.forEach((item) => {
            //             item.classList.toggle('reveal');
            //       });
                   
               
            //     let notItems = document.body.querySelectorAll(':not([data-type="' + content + '"])')
            //     notItems.forEach((notItem) => {
            //         notItem.classList.remove('reveal');
            //     });

            //     // Highlight phase indicators
            //     if (content == 'journey-discover' || content == 'journey-define') {
            //         $('.phase.one').addClass('active');
            //         $('.phase.two').removeClass('active');
            //         $('.phase.three').removeClass('active');
            //     } else if (content == 'journey-deploy' || content == 'journey-deliver') {
            //         $('.phase.two').addClass('active');
            //         $('.phase.one').removeClass('active');
            //         $('.phase.three').removeClass('active');
            //     } else if (content == 'journey-evolve') {
            //         $('.phase.three').addClass('active');
            //         $('.phase.one').removeClass('active');
            //         $('.phase.two').removeClass('active');
            //     }

            //     // play animations
            //     if ($('.discover.main').hasClass('reveal')) {
            //         discoverAnim.play();
            //     } else {
            //         discoverAnim.pause();
            //     }

            //     if ($('.define.main').hasClass('reveal')) {
            //         defineAnim.play();
            //     } else {
            //         defineAnim.pause();
            //     }

            //     if ($('.deploy.main').hasClass('reveal')) {
            //         deployAnim.play();
            //     } else {
            //         deployAnim.pause();
            //     }

            //     if ($('.deliver.main').hasClass('reveal')) {
            //         deliverAnim.play();
            //     } else {
            //         deliverAnim.pause();
            //     }

            //     if ($('.evolve.main').hasClass('reveal')) {
            //         evolveAnim.play();
            //     } else {
            //         evolveAnim.pause();
            //     }
            // });

        }
    };
    carouselMediaCall();

    $('.complete-journey button.main, .complete-journey h1.main').on('click', function () {

        var content = $(this).attr('data-type');

        console.log(content)

        // $('[data-type = ' + content + ']').toggleClass('reveal')

        let items = document.body.querySelectorAll('[data-type="' + content + '"]')
        // console.log( items)
        items.forEach((item) => {
                item.classList.toggle('reveal');
          });
           
       
        let notItems = document.body.querySelectorAll(':not([data-type="' + content + '"])')
        notItems.forEach((notItem) => {
            notItem.classList.remove('reveal');
        });

        // Highlight phase indicators
        if (content == 'journey-discover' || content == 'journey-define') {
            $('.phase.one').addClass('active');
            $('.phase.two').removeClass('active');
            $('.phase.three').removeClass('active');
        } else if (content == 'journey-deploy' || content == 'journey-deliver') {
            $('.phase.two').addClass('active');
            $('.phase.one').removeClass('active');
            $('.phase.three').removeClass('active');
        } else if (content == 'journey-evolve') {
            $('.phase.three').addClass('active');
            $('.phase.one').removeClass('active');
            $('.phase.two').removeClass('active');
        }

        // play animations
        if ($('.discover.main').hasClass('reveal')) {
            discoverAnim.play();
        } else {
            discoverAnim.pause();
        }

        if ($('.define.main').hasClass('reveal')) {
            defineAnim.play();
        } else {
            defineAnim.pause();
        }

        if ($('.deploy.main').hasClass('reveal')) {
            deployAnim.play();
        } else {
            deployAnim.pause();
        }

        if ($('.deliver.main').hasClass('reveal')) {
            deliverAnim.play();
        } else {
            deliverAnim.pause();
        }

        if ($('.evolve.main').hasClass('reveal')) {
            evolveAnim.play();
        } else {
            evolveAnim.pause();
        }
    });

    offCanvas.forEach(function (a) {
        a.addEventListener("click", function (e) {
            e.preventDefault();
            gsap.to(window, {
                duration: 0.6,
                // autoKill: true, 
                scrollTo: { y: e.target.getAttribute("href"), offsetY: navHeight },
                ease: 'quart.inOut',
            });
        });
    });

    // console.log(navHeight)

    navLinks.forEach(function (a) {
        a.addEventListener("click", function (e) {
            e.preventDefault();
            gsap.to(window, {
                duration: 0.6,
                // autoKill: true, 
                scrollTo: { y: e.target.getAttribute("href"), offsetY: navHeight },
                ease: 'quart.inOut',
            });
        });
    });

    completeJourney.forEach(function (a) {
        a.addEventListener("click", function (e) {
            e.preventDefault();
            gsap.to(window, {
                duration: 0.6,
                // autoKill: true, 
                scrollTo: { y: e.target.getAttribute("href"), offsetY: navHeight },
                ease: 'quart.inOut',
            });
        });
    });

    panels.forEach((panel, i) => {
        const panelTrigger = gsap.timeline({
            scrollTrigger: {
                // markers:true,
                trigger: panel,
                start: "top 75%",
                end: "top -100%",
                toggleActions: "play none none reset",
                onEnter: (self) => {
                    panel.classList.add("mobile-reveal");
                    // console.log(panel.children[2].children[1].children[0].id)
                    let currentAnim = panel.children[2].children[1].children[0].id;
                    currentAnim = currentAnim.slice(0, -5); 
                    
                    if (currentAnim == 'discoverAnim') {
                        discoverAnim.play();
                    } else {
                        discoverAnim.pause();
                    }
                    if (currentAnim == 'defineAnim') {
                        defineAnim.play();
                    } else {
                        defineAnim.pause();
                    }
                    if (currentAnim == 'deployAnim') {
                        deployAnim.play();
                    } else {
                        deployAnim.pause();
                    }
                    if (currentAnim == 'deliverAnim') {
                        deliverAnim.play();
                    } else {
                        deliverAnim.pause();
                    }
                    if (currentAnim == 'evolveAnim') {
                        evolveAnim.play();
                    } else {
                        evolveAnim.pause();
                    }

    
                },
                onLeave: () => {
                    panel.classList.remove("mobile-reveal");
      
                },
                onEnterBack: () => {
                    panel.classList.add("mobile-reveal");
                    let currentAnim = panel.children[2].children[1].children[0].id;
                    currentAnim = currentAnim.slice(0, -5); 
                    if (currentAnim == 'discoverAnim') {
                        discoverAnim.play();
                    } else {
                        discoverAnim.pause();
                    }
                    if (currentAnim == 'defineAnim') {
                        defineAnim.play();
                    } else {
                        defineAnim.pause();
                    }
                    if (currentAnim == 'deployAnim') {
                        deployAnim.play();
                    } else {
                        deployAnim.pause();
                    }
                    if (currentAnim == 'deliverAnim') {
                        deliverAnim.play();
                    } else {
                        deliverAnim.pause();
                    }
                    if (currentAnim == 'evolveAnim') {
                        evolveAnim.play();
                    } else {
                        evolveAnim.pause();
                    }
   
                },
                onLeaveBack: () => {
                    panel.classList.remove("mobile-reveal");
    
                },
            }
        });
    });

    phases.forEach((phase, i) => {
        const panelTrigger = gsap.timeline({
            scrollTrigger: {
                // markers:true,
                trigger: phase,
                start: "top 75%",
                end: "top -100%",
                toggleActions: "play none none reset",
                onEnter: (self) => {
                    phase.classList.add("mobile-active");
                },
                onLeave: () => {
                    phase.classList.remove("mobile-active");
                },
                onEnterBack: () => {
                    phase.classList.add("mobile-active");
                },
                onLeaveBack: () => {
                    phase.classList.remove("mobile-active");
                },
            }
        });
    });


    cardWrap.forEach((card, i) => {
        const cardWrapTrigger = gsap.timeline({
            scrollTrigger: {
                // markers:true,
                trigger: card,
                start: "top 70%",
                end: "top top",
                toggleActions: "play none none reset",
                onEnter: (self) => {
                    card.classList.add("mobile-reveal");
                },
                onLeave: () => { },
                onEnterBack: () => { },
                onLeaveBack: () => {

                },
            }
        });
    });

    sections.forEach((section, i) => {

        const sectionTrigger = gsap.timeline({
            scrollTrigger: {
                // markers:true,
                trigger: section,
                start: "top top",
                end: "top top",
                toggleActions: "play none none reset",
                onEnter: () => {
                    $(".section-nav a[href$='" + $(section).attr('id') + "']").addClass('active');
                    $(".section-nav a").not("[href$='" + $(section).attr('id') + "']").removeClass('active');
                },
                onLeave: () => { },
                onEnterBack: () => { },
                onLeaveBack: () => {
                    $(".section-nav a[href$='" + $(section).attr('id') + "']").addClass('active');
                    $(".section-nav a").not("[href$='" + $(section).attr('id') + "']").removeClass('active');
                },
            }
        });

    });

    // Adjust markers for off canvas menu
    sections.forEach((section, i) => {
        const sectionTrigger2 = gsap.timeline({
            scrollTrigger: {
                // markers:true,
                trigger: section,
                start: "top center",
                end: "top 5%",
                toggleActions: "play none none reset",
                onEnter: () => {
                    $(".offcanvas-menu a[href$='" + $(section).attr('id') + "']").addClass('active');
                    $(".offcanvas-menu a").not("[href$='" + $(section).attr('id') + "']").removeClass('active');
                },
                onLeave: () => { },
                onEnterBack: () => {
                    $(".offcanvas-menu a[href$='" + $(section).attr('id') + "']").addClass('active');
                    $(".offcanvas-menu a").not("[href$='" + $(section).attr('id') + "']").removeClass('active');
                },
                onLeaveBack: () => {
                },
            }
        });

    });

    // Basic add class
    const activeobj = gsap.utils.toArray('.activate');
    // console.log(activeobj);
    activeobj.forEach((sec, i) => {
        ScrollTrigger.create({
            trigger: sec,
            start: "top 75%",
            end: "bottom 75%",
            toggleActions: 'play none none none',
            onEnter: () => {
                sec.classList.add("activated");
            }
        });
    });


    const whiteBg = gsap.utils.toArray('.white-bg');
    const sectionNav = document.querySelector(".section-nav");
    // console.log(whiteBg);

    whiteBg.forEach((sec, i) => {
        // const anim = gsap.fromTo(box, {autoAlpha: 0, y: 50}, {duration: 1, autoAlpha: 1, y: 0});
        ScrollTrigger.create({
            trigger: sec,
            // markers: true,
            start: "top 65%",
            end: "bottom 65%",
            // animation: anim,
            toggleActions: 'play none none none',
            // once: true,
            // scroller: container,
            onEnter: () => {
                sectionNav.classList.add("gray");
                // console.log(sectionNav)
            },
            onLeave: () => {
                sectionNav.classList.remove("gray");
            },
            onEnterBack: () => {
                sectionNav.classList.add("gray");
                // console.log(sectionNav)
            },
            onLeaveBack: () => {
                sectionNav.classList.remove("gray");
            }
        });
    });

    $.fn.digits = function () {
        return this.each(function () {
            $(this).text($(this).text().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
        })
    }
    // Animated numbers
    $(".counts").each(function (index, element) {
        var count = $(this),
            zero = { val: 0 },
            num = count.data("number"),
            split = (num + "").split(","),
            decimals = split.length > 1 ? split[1].length : 0;
        // console.log(split)

        gsap.to(zero, {
            val: num,
            duration: 2.5,
            scrollTrigger: {
                // markers: true,
                trigger: element,
                start: "bottom 85%",
                // end: "+=500"
            },
            ease: "power2.out",
            onUpdate: function () {
                count.text(zero.val.toFixed(decimals)).digits();
            }
        });
    });




    $('.menu button').on('click', function () {
        var content = $(this).attr('data-type');

        $('button[data-type = ' + content + '], div[data-type = ' + content + ']').addClass('active');
        $('button:not([data-type = ' + content + ']), div:not([data-type = ' + content + '])').removeClass('active');
    });



    // extension method:
    $.fn.classChange = function (cb) {
        return $(this).each((_, el) => {
            new MutationObserver(mutations => {
                mutations.forEach(mutation => cb && cb(mutation.target, $(mutation.target).prop(mutation.attributeName)));
            }).observe(el, {
                attributes: true,
                attributeFilter: ['class'] // only listen for class attribute changes 
            });
        });
    }






    

    // Inner drawers 
    $('.panel-menu button').on('click', function () {
        var content = $(this).attr('data-drawer');

     
        $(this).toggleClass('active');

        $('.panel-menu button').not(this).removeClass('active');


        if ($(this).hasClass('active')) {
            // if panel already is open and it is clicked close it
            $('.panel-drawer [data-drawer = ' + content + ']').addClass('active');
            // Lets close all other panels
            $('.panel-drawer :not([data-drawer = ' + content + '])').removeClass('active');
            // Hide the close panel button
            $(this).parent().siblings('.panel-drawer').find('.close-btn').addClass('active');

        } else {

            $('.panel-drawer [data-drawer = ' + content + ']').removeClass('active');
            $('.panel-drawer :not([data-drawer = ' + content + '])').removeClass('active');
            $(this).parent().siblings('.panel-drawer').find('.close-btn').removeClass('active');

        }


    });


    $('.close-btn').on('click', function () {
        $('.drawer').removeClass('active');
        $(this).removeClass('active');
        $('.panel-menu button').removeClass('active');
    });


    $('button.hamburger').on('click', function () {
        $(this).toggleClass('is-active');
        // $('.offcanvas-menu').addClass('active');

        if (!$('.offcanvas-menu').hasClass('active')) {
            $('.offcanvas-menu').addClass('active');
        } else {
            $('.offcanvas-menu').removeClass('active');
        }
    });


    // open and close the offcanvas menu
    const mediaQuery = '(min-width: 767px)';
    const mediaQueryList = window.matchMedia(mediaQuery);

    if (mediaQueryList.matches) {
        // console.log('this mathces');
        $('.section-nav').on("mouseover", function () {
            $('.offcanvas-menu').addClass("active");
        })
        $('.offcanvas-menu').on("mouseleave", function () {
            $('.offcanvas-menu').removeClass("active");
            $('button.hamburger').removeClass("is-active");
        })
    }

    mediaQueryList.addEventListener('change', event => {
        // console.log(window.innerWidth);
        if (event.matches) {
            $('.section-nav').on("mouseover", function () {
                $('.offcanvas-menu').addClass("active");
            })
            $('.offcanvas-menu').on("mouseleave", function () {
                $('.offcanvas-menu').removeClass("active");
                $('button.hamburger').removeClass("is-active");
            })
        } else {

        }
    })


    // Form spree stuff
    var form = document.getElementById("vg-form");

    async function handleSubmit(event) {

        var emailValue = document.getElementById("email").value;

        event.preventDefault();


        // Prevent multiple clicking of submit by disabling button for 3 seconds
        let submitButton = document.getElementById('form-submit');
        console.log(submitButton);
        submitButton.classList.add('disabled');
        submitButton.disabled = true;
        setTimeout(() => {
            submitButton.classList.remove('disabled');
            submitButton.disabled = false;
        }, "3000")

        const url = "https://api.antideo.com/email/" + emailValue + "?apiKey=00141186c11e66b039314a190f284ecf";
        fetch(url, {
            method: "GET",
        })
            .then(resp => resp.json())
            .then(function (data) {
                // first check if email is good or not 
                console.log(data)
                if (data.scam == false && data.spam == false) {
                    document.getElementById("scamCheck").value = "Cleared Spam Check";
                    console.log('cleared spam check')

                } else {
                    document.getElementById("scamCheck").value = "Potential Spam";
                    console.log('Spam')
                }

                if (data.free_provider == true) {
                    console.log('this is a free email')
                    document.querySelector(".text-red").style.display = "block";
                } else {
                    document.querySelector(".text-red").style.display = "none";
                    // Then fetch and submit the form
                    var status = document.querySelector("#my-form-status");
                    console.log(status);
                    var data = new FormData(event.target);
                    fetch(event.target.action, {
                        method: form.method,
                        body: data,
                        headers: {
                            'Accept': 'application/json'
                        }
                    }).then(response => {
                        console.log(response)
                        if (response.ok) {
                            status.innerHTML = "Thanks for your submission!";
                            form.reset()
                        } else {
                            response.json().then(data => {
                                if (Object.hasOwn(data, 'errors')) {
                                    status.innerHTML = data["errors"].map(error => error["message"]).join(", ")
                                } else {
                                    status.innerHTML = "Oops! There was a problem submitting your form"
                                }
                            })
                        }
                    }).catch(error => {
                        console.log(error)
                        status.innerHTML = "Oops! There was a problem submitting your form"
                    });
                }// End email provider check.
            })


            .catch(function (error) {
                console.log(error);
            });


    }
    form.addEventListener("submit", handleSubmit)



});

